import { FC, Suspense } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuthProvider } from "../../providers/auth.provider";
import { useUserProvider } from "../../providers/user.provider";
import { PageLoading } from "../organisms/page-loading";
import { Layout } from "../templates/_layout";
import { useAgencyProvider } from "../../providers/agency.provider";
import { useSubscriptionProvider } from "../../providers/subscription.provider";
import { useCanAccess } from "../../hooks/use-can-access";

export const ProtectedRoute: FC<{ hiddenLayout?: boolean }> = ({
  hiddenLayout = false,
}) => {
  const [{ isAuthenticated }] = useAuthProvider();
  const { user } = useUserProvider();
  const { agency } = useAgencyProvider();
  const { subscription } = useSubscriptionProvider();
  const location = useLocation();
  const canAccess = useCanAccess(
    location.pathname.replace(/^\//, "").replace(/\//g, ".")
  );
  const requireRenovateSubscription = subscription?.state === "unavailable";

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  if (!user) {
    return <PageLoading />;
  }

  if (!agency || !subscription) {
    return <PageLoading />;
  }

  if (
    requireRenovateSubscription &&
    location.pathname !== "/onboarding/renovate-subscription"
  ) {
    console.log("Redirecting to /onboarding/renovate-subscription", {
      path: location.pathname,
      sub: subscription,
    });
    return <Navigate to="/onboarding/renovate-subscription" replace />;
  }

  if (
    agency.flags.onboarding !== true &&
    location.pathname !== "/onboarding/welcome" &&
    !requireRenovateSubscription
  ) {
    console.log('Redirecting to "/onboarding/welcome"', { agency });
    return <Navigate to="/onboarding/welcome" replace />;
  }

  if (
    user.agencies.length === 0 &&
    location.pathname !== "/onboarding/welcome-agent" &&
    !requireRenovateSubscription
  ) {
    console.log('Redirecting to "/onboarding/welcome-agent"');
    return <Navigate to="/onboarding/welcome-agent" replace />;
  }

  if (hiddenLayout) {
    return (
      <Suspense fallback={<PageLoading />}>
        <Outlet />
      </Suspense>
    );
  }

  if (!canAccess) {
    const parentPath =
      location.pathname.split("/").slice(0, -1).join("/") || "/";

    console.log("Redirecting becouse not have access to", parentPath);

    return <Navigate to={parentPath} replace />;
  }

  return (
    <Layout>
      <Suspense fallback={<PageLoading />}>
        <Outlet />
      </Suspense>
    </Layout>
  );
};
