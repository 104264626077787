var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { DetectAgencyDto, } from "@serviestate/shared/types";
import { parseException } from "../errors";
import { UseCache } from "../use-cache";
export class AgenciesController {
    constructor(axios) {
        this.axios = axios;
    }
    async detectAgency(dto) {
        try {
            const response = await this.axios.post("/agencies/detector", dto);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async getConfig(name) {
        try {
            const response = await this.axios.get(`/agencies/config/${name}`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async saveConfig(name, value) {
        try {
            const response = await this.axios.post(`/agencies/config/${name}`, value);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async getIntegration(channelCode) {
        try {
            const response = await this.axios.get(`/agencies/integrations/${channelCode}`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async getIntegrations() {
        try {
            const response = await this.axios.get("/agencies/integrations");
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async deactivateIntegration(channelCode) {
        try {
            const response = await this.axios.post(`/agencies/integrations/${channelCode}/deactivate`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async configureIntegration(dto) {
        try {
            const response = await this.axios.post("/agencies/integrations", dto);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async getLanguages() {
        try {
            const response = await this.axios.get("/agencies/languages");
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async getActive() {
        try {
            const response = await this.axios.get("/agencies/active");
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async getProfile(agencyId) {
        try {
            const response = await this.axios.get(`/agencies/${agencyId}/profile`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async updateFiscalData(dto) {
        try {
            const response = await this.axios.post(`/agencies/fiscal-data`, dto);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async saveProfile(dto) {
        try {
            const response = await this.axios.patch(`/agencies`, dto);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async saveOnboardingInfo(data) {
        try {
            const response = await this.axios.post(`/agencies/onboarding`, data !== null ? data : {});
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async finishOnboarding() {
        try {
            const response = await this.axios.post(`/agencies/finish-onboarding`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async getHomeLink() {
        try {
            const response = await this.axios.get(`/agencies/home/link`);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
}
__decorate([
    UseCache({
        keyFactory: (dto) => { var _a; return `${(_a = dto.hostname) !== null && _a !== void 0 ? _a : ""}_${dto.explicit_agency_id}`; },
    }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [DetectAgencyDto]),
    __metadata("design:returntype", Promise)
], AgenciesController.prototype, "detectAgency", null);
